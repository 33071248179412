import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  gtag: any;

  constructor() {
    this.gtag = this.getGTAGlobal();
  }

  getGTAGlobal() {
    if ((window as any).gtag) {
      return (window as any).gtag;
    }
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/setting-values */
  public set(params: any): void {
    return this.gtag('set', params);
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/events */
  public event(action: string, params?: any): Promise<void> {
    // Wraps the event call into a Promise
    return new Promise((resolve, reject) => {
      try {
        // Triggers a 3s time-out timer
        const tmr = setTimeout(
          () => reject(new Error('gtag call timed-out')),
          3000
        );
        // Performs the event call resolving with the event callback
        this.gtag('event', action, {
          ...params,
          event_callback: () => {
            clearTimeout(tmr);
            resolve();
          },
        });
      } catch (e) {
        // Rejects the promise on errors
        reject(e);
      }
    });
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
  public pageView(
    page_title?: string,
    page_path?: string,
    page_location?: string
  ) {
    return this.event('page_view', { page_title, page_location, page_path });
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions */
  public exception(description?: string, fatal?: boolean) {
    return this.event('exception', { description, fatal });
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings */
  public timingComplete(
    name: string,
    value: number,
    event_category?: string,
    event_label?: string
  ) {
    return this.event('timing_complete', {
      name,
      value,
      event_category,
      event_label,
    });
  }

  public login(method?: string) {
    return this.event('login', { method });
  }

  public signUp(method?: string) {
    return this.event('sign_up', { method });
  }

  public search(search_term?: string) {
    return this.event('search', { search_term });
  }
}
