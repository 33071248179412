import { HttpClient, HttpHeaders, HttpParamsOptions, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { pick, without } from 'lodash';

import { Observable, of } from 'rxjs';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';


import { AppConfig, APP_CONFIG } from './config';

import { AuthService } from './auth.service';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  tenant_id = '';
  base_url = '';
  constructor(
    private http: HttpClient,
    private uiState: AuthService,
    
    @Inject(APP_CONFIG) config: AppConfig
  ) {
    if (config.default_tenant_id) {
      this.setTenantId(config.default_tenant_id);
    }
    if (config.base_url) {
      this.base_url = config.base_url;
    }
    let apiUrlForDev = localStorage.getItem('apiUrl');
    if(apiUrlForDev) {
      this.base_url = apiUrlForDev;
    }
  }

  checkUrlSuffix(url: string) {
    if (url.substr(0, 1) != '/') {
      return '/' + url;
    }
    return url;
  }

  getBaseUrl() {
    return this.base_url;
  }

  setTenantId(tenant_id: string) {
    this.uiState.setTenantId(tenant_id);
    this.tenant_id = tenant_id;
  }

  getMe() {
    return this.http.get(this.base_url + this.checkUrlSuffix('/auth/me'));
  }

  confirmUser(token: string) {
    return this.http.get(this.base_url + '/confirmUser/' + token);
  }
  login(body: any) {
    if (this.tenant_id) {
      //Nothing
    } else if (body.hasOwnProperty('tenant_id')) {
      this.tenant_id = body.tenant_id;
      this.uiState.tenant_id = body.tenant_id;
      this.uiState.updateProps({ tenant_id: body.tenant_id });
    }
    return this.http
      .post(this.base_url + '/auth/login', pick(body, ['email', 'password']))
      .pipe(
        tap((response: any) => {
          this.uiState.setJwt(response.access_token, response.expires_in);
        })
      );
  }

  refresh() {
    return this.uiState.select((state) => state.jwt)
      .pipe(
        mergeMap((jwt) => {
          if ((jwtDecode(jwt) as any).exp < (new Date().getTime() + 1) / 1000) {
            return this.http.post(this.base_url + '/auth/refresh', {}).pipe(
              tap((response: any) => {
                this.uiState.setJwt(response.access_token, response.expires_in);
              })
            );
          } else {
            return of(false);
          }
        })
      );
  }

  register(userForm: any) {
    let body: {
      firstname?: string;
      lastname?: string;
      email?: string;
      password?: string;
    } = {};
    if (userForm.firstname && userForm.lastname) {
      body.firstname = userForm.firstname;
      body.lastname = userForm.lastname;
    }
    body.email = userForm.email;
    body.password = userForm.password;
    return this.http.post(this.base_url + '/user', body);
  }

  get<Object>(url: string) :Observable<Object> {
    return this.http.get(this.getBaseUrl() + this.checkUrlSuffix(url)) as Observable<Object>;
  }

  getFile(url: string) {
    const httpOptions:any = {
     
      responseType : 'arraybuffer',
      observe: 'response'
    };
    return this.http.get(this.getBaseUrl() + this.checkUrlSuffix(url), httpOptions);
  }

  postGetFile(url:string, body:any): Observable<HttpResponse<ArrayBuffer>>{
    return this.http.post(this.getBaseUrl() + this.checkUrlSuffix(url), body,  {
     
      responseType : 'arraybuffer',
      observe: 'response'
    });
  }
  post(url: string, body: any) {
    return this.http.post(this.getBaseUrl() + this.checkUrlSuffix(url), body);
  }

  put(url: string, body: any) {
    return this.http.put(this.getBaseUrl() + this.checkUrlSuffix(url), body);
  }
  delete(url: string) {
    return this.http.delete(this.getBaseUrl() + this.checkUrlSuffix(url));
  }

  patch(url:string, body:any) {
    return this.http.patch(this.getBaseUrl() + this.checkUrlSuffix(url), body);
  }

  getAllPages(url:string, suffix:string = '', limit:number =100)  {
    let result: any[];
    return this.http.get(this.base_url + this.checkUrlSuffix(url)+'?limit='+limit+suffix).pipe(
      concatMap((response: any) => {
        result = response.data;
        if (response.links.next != null) {
          return this.getNextPage(
            this.base_url+ this.checkUrlSuffix(url)+'?limit='+limit+suffix,
            response.meta,
            result
          );
        }
        if(response.meta.current_page == 1) {
          return of(response.data);
        }
        return of([]);
      }),
      concatMap((data: any) => {
        return of(data);
      })
    );
  }
  getProducts() {
    let url = '/product?limit=100&include=tags,producers'
    let result: any[];
    return this.http.get(this.base_url + url).pipe(
      concatMap((response: any) => {
        result = response.data;
        if (response.links.next != null) {
          return this.getNextPage(
            this.base_url + url,
            response.meta,
            result
          );
        }
        if(response.meta.current_page == 1) {
          return of(response.data);
        }
        return of([]);
      }),
      concatMap((data: any) => {
        return of(data);
      })
    );
  }

  getNextPage(
    baseurl: string,
    meta: { current_page: string; last_page: string },
    result: any[]
  ): Observable<any> {
    return this.http
      .get(baseurl + '&page=' + (Number.parseInt(meta.current_page) + 1))
      .pipe(
        concatMap((response: any) => {
          if (meta.current_page + 1 < meta.last_page) {
            result = [...response.data, ...result];
            return this.getNextPage(baseurl, response.meta, result);
          }
          result = [...response.data, ...result];
          return of(result);
        })
      );
  }

  getBalance() {
    return this.http.get(this.base_url + '/customer/accounting');
  }
}
