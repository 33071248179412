import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep, isArray } from 'lodash';
import { map, mergeMap, tap } from 'rxjs/operators';

import { IProduct } from '@app/data-interfaces';

import { ICart } from '@pisci/data-cart';
import { OrderQuery } from './order.query';
import { OrderStore } from './order.store';
import { RequestService } from '@pisci/requestManager';
import { IOrder, Order } from '@app/shared/data-cart2';
import { Observable } from 'rxjs';
import { createStore, filterNil } from '@ngneat/elf';
import { selectAllEntities, selectEntity, setEntities, updateEntities, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import * as _ from 'lodash';

const orderStore = createStore({name:'orders'}, withEntities<IOrder&{id:string}>(),
withRequestsCache());
export const skipWhileTodosCached = createRequestsCacheOperator(orderStore);

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private http: HttpClient,
    private orderStore: OrderStore,
    private orderQuery: OrderQuery,
    private requestService: RequestService
  ) {}
  searchOrder(term: string) {
    return this.requestService
      .post('/order/search', {
        search: {
          value: term,
        },
      })
      .pipe(map((result: any) => result.data));
  }

  update(id:string, body:any) {
    return this.requestService.put('order/'+id, body).pipe(tap( () => {
      this.loadOrder(id).subscribe();
    }))
  }

  getMyOrders(): Observable<Order[]> {
    return this.requestService.get('me/orders').pipe(
      map((orderResponse) => {
        if (isArray(orderResponse)) {
          return _.orderBy(orderResponse.map((order) => new Order(order)) as Order[], 'id', 'desc');
        } else {
          return [];
        }
      })
    );
  }
  getOrders() {
   
 return orderStore.pipe(selectAllEntities());
    
  }

  loadOrders() {
    return this.requestService.getAllPages('order').pipe(
      tap( orders => {
        orderStore.update(   updateRequestCache('orders'),setEntities(orders));
      }),
      skipWhileTodosCached('orders' )
    );
  }

  getOrder(id: string): Observable<Order> {
    return orderStore.pipe(selectEntity(id), filterNil(), map((orderRespone) => new Order(orderRespone)));
    return this.requestService
      .get<IOrder>('order/' + id + '?include=invoices')
      .pipe(map((orderRespone) => new Order(orderRespone)));
  }

  loadOrder(id:string) {
    return this.requestService
    .get<IOrder>('order/' + id + '?include=invoices')
    .pipe(tap((orderRespone) => {
      orderStore.update(updateEntities(id, orderRespone))
    }));
  }
  createOrder(
    basket: ICart,
    billing_address: any,
    shipping_address: any,
    user_id: number | undefined
  ) {
    let body: {
      basket: ICart;
      billing_address: any;
      shipping_address: any;
      user_id?: any;
    } = {
      basket,
      billing_address,
      shipping_address,
    };
    if (user_id != undefined) {
      body.user_id = user_id;
    }
    return this.requestService.post('/order', body);
  }

  createSubscription(
    basket: ICart,
    billing_address: any,
    shipping_address: any,
    user_id: number | undefined
  ) {
    let body: {
      basket: ICart;
      billing_address: any;
      shipping_address: any;
      user_id?: any;
    } = {
      basket,
      billing_address,
      shipping_address,
    };
    if (user_id != undefined) {
      body.user_id = user_id;
    }
    return this.requestService.post('/subscription', body);
  }
}
