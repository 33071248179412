<table mat-table [dataSource]="dataSource" class="ui-mini-cart-table">
  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let element"><!--{{element.id}} --></td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef>Menge</th>
    <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="amountGross">
    <th mat-header-cell *matHeaderCellDef>€</th>
    <td mat-cell *matCellDef="let element">
      {{ element.amountGross | number: '1.2' }} €
    </td>
  </ng-container>
  <ng-container matColumnDef="buttons">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button class="btn_remove" (click)="removeLineClicked(element)">
        <ion-icon name="close-circle-outline"></ion-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div class="sums">
  Gesamtsumme: {{ cartService.selectTotal() | async | number: '1.2' }}€ zzgl.
  Versandkosten
</div>

<ion-button expand="block" size="large" (click)="goToCheckoutClicked()"
  >Zur Kassa</ion-button
>
<ng-content></ng-content>
