import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';
import { CartEntityState, CartStore } from './cart.store';

@Injectable({ providedIn: 'root' })
export class CartQuery extends QueryEntity<CartEntityState> {
  constructor(protected override store: CartStore) {
    super(store);
  }
}
