import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompleteComponent } from './complete/complete.component';
import { PageComponent } from './page/page.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ShippingInformationComponent } from './shipping-information/shipping-information.component';
import { UiProductDetailComponent } from './ui-product-detail/ui-product-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'category', pathMatch: 'full' },
  { path: 'category', component: ProductListComponent },
  { path: 'category/:id', component: ProductListComponent },
  { path: 'product/:id', component: UiProductDetailComponent },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./checkout/checkout.module').then((m) => m.CheckoutModule),
  },
  { path: 'complete', component: CompleteComponent },
  { path: 'complete/:name', component: CompleteComponent },
  { path: 'shipping', component: ShippingInformationComponent },
  { path: 'page/:id', component: PageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,   { enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
