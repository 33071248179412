import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DefaultComponent } from './layout/default/default.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { IonicModule } from '@ionic/angular';
import { ProductListComponent } from './product-list/product-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiProductDetailComponent } from './ui-product-detail/ui-product-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UiMiniCartModule } from '@cw/mini-cart';
import { MatTableModule } from '@angular/material/table';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_CONFIG, HttpRequestInterceptor } from '@pisci/requestManager';
import { DB_CONFIG } from './app.config';
import { MATERIAL_MODULES } from '@app/shared/material-imports';
import { CompleteComponent } from './complete/complete.component';
import { ShippingInformationComponent } from './shipping-information/shipping-information.component';
import { PageComponent } from './page/page.component';

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    ProductListComponent,
    UiProductDetailComponent,
    CompleteComponent,
    ShippingInformationComponent,
    PageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    IonicModule.forRoot(),
    ...MATERIAL_MODULES,
    UiMiniCartModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    { provide: APP_CONFIG, useValue: DB_CONFIG },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
