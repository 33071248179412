import { AppConfig } from '@pisci/requestManager';
import { environment } from '../environments/environment';
let config ;
if(environment.production) {
 config = {default_tenant_id: 'grossShop',
 base_url: 'https://api.logistikmanager.pisci.eu/api'}
} else {
  config = {
    base_url:'https://api.dev.logistikmanager.pisci.eu/api',
    default_tenant_id:'gross_test'
  }
}

export const DB_CONFIG: AppConfig = config;
 