import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthQuery } from './auth.query';

import { RequestService } from './request.service';

export interface AuthState {
  jwt: string;
  tenant_id: string | null;
  user: any;
}
const authStore =  createStore({name:'auth'}, 
withProps<AuthState>( {
  jwt: '',
  tenant_id: null,
  user: null,
}));

import jwt_decode from 'jwt-decode';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  onSubject = new Subject<any>();
  tenant_id: string | null = '';
  jwt = '';
  constructor(
   
  ) {
    let jwt = <string>localStorage.getItem('jwt');
    if (jwt) {
      authStore.update(setProps({ jwt: jwt }));
    }
    let tenant = <string>localStorage.getItem('tenant_id');
    if (tenant) {
      authStore.update(setProps({ tenant_id: tenant }));
    }

      this
      .select((state) => {
        this.tenant_id = state.tenant_id;
        this.jwt = state.jwt;
      })
      .subscribe();
  }

  getValue() {
    return authStore.getValue();
  }

  select(stateFunction : (state:AuthState) => any){
    return authStore.pipe(select(stateFunction))
  }

  setTenantId(tenant_id: string) {
    this.tenant_id = tenant_id;
    localStorage.setItem('tenant_id', tenant_id);
    authStore.update(setProps({ tenant_id: tenant_id }));
  }

  setJwt(jwt: string, expires: number) {
    localStorage.setItem('jwt', jwt);
    let date = new Date();

    localStorage.setItem(
      'expires',
      new Date(date.getTime() + expires * 1000).getTime().toString()
    );
    authStore.update(setProps({ jwt }));
  }

  updateProps ( props: Partial<AuthState>) {
    authStore.update(setProps(props))
  }

  deleteLoginData() {
    authStore.update(setProps({jwt:undefined}));
    //this.store.destroy();
  }

  logout() {
    this.deleteLoginData();
    
  }

  startMessageListening() {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  userConfirmedEvent() {
    return this.onSubject.asObservable().pipe(
      tap((event) => {
        console.log(event);
      }),
      filter((value) => {
        return value.key == 'message' && value.value == 'user_confirmed';
      })
    );
  }

  rerouteEvent() {
    return this.onSubject.asObservable().pipe(
      filter((value) => {
        return value.key == 'message' && value.value == 'route_checkout';
      })
    );
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage && event.newValue !== null) {
      let v;
      try {
        v = JSON.parse(event.newValue);
      } catch (e) {
        v = event.newValue;
      }
      this.onSubject.next({ key: event.key, value: v });
    }
  }
}
