import { Injectable } from '@angular/core';
import {
  persistState,
  localStorageStrategy,
  sessionStorageStrategy,
} from '@ngneat/elf-persist-state';

import {
  ILineItem,
  IOrderDTO,
  IPrice,
  IProduct,
  OrderDTO,
} from '@app/data-interfaces';
import { IAddress, ICart, ICartLine, IOrder } from './cart.interface';
import {
  combineLatest,
  EMPTY,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import {
  auditTime,
  catchError,
  delay,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { RequestService } from '@pisci/requestManager';
import { cloneDeep, isNumber } from 'lodash';
import { Utils } from '@app/utils';

import {
  createStore,
  distinctUntilArrayItemChanged,
  emitOnce,
  isString,
  select,
  setProp,
  setProps,
  withProps,
} from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  deleteEntities,
  getAllEntities,
  getEntitiesCount,
  selectAllEntities,
  selectEntities,
  selectEntitiesCount,
  updateEntities,
  withEntities,
} from '@ngneat/elf-entities';
import { dispatch, ofType } from '@ngneat/effects';
import {
  addressesChanged,
  dataChanged,
  proposeDefaultAddress,
  proposePhoneNumber,
  updatedItems,
} from './cart.action';
import { Actions } from '@ngneat/effects-ng';
import { removeEntities } from '@datorama/akita';
import * as _ from 'lodash';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface CartEntityState {
  cartTotal: number;
  totalPrice?: IPrice;
  orderDraft?: IOrder;
  orderDTO: any;
  shippingAddress?: IAddress;
  billingAddress?: IAddress;
  sameAddress?: boolean;
  userId?: number;
  customerId?: number;
  deliveryDate?: Date | null;
  data?: any;
  totalDiscount?: number;
  latestId: number;
  shipmentOption?: {
    id: number;
    price: IPrice;
    name: string;
  };
  paymentMethod?: string;
  vouchers: any[];
  customer_note?: string;
}
const cartStore = createStore(
  { name: 'cart' },
  withEntities<ICartLine>(),
  withProps<CartEntityState>({
    cartTotal: 0,
    orderDTO: {},
    latestId: 0,
    vouchers: [],
  })
);
export const persist = persistState(cartStore, {
  key: 'auth',
  storage: localStorageStrategy,
});

persist.initialized$.subscribe(() => {
  let foundNull = cartStore.getValue().vouchers.findIndex((v) => v == null);
  if (foundNull !== -1) {
    cartStore.update(setProp('vouchers', []));
  }
});

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CartService {
  zipCodes: string[] = [];
  beforeAdd$ = new Subject();
  afterAdd$ = new Subject();

  requiredFields: string[] = [];
  constructor(private rm: RequestService, private actions: Actions) {
    //this.initLiveTotalCalculation().subscribe();
    /*cartStore
      .pipe(
        selectAllEntities(),

        switchMap((entitites) => {
          entitites;
          return of(this.calculateTotal(entitites));
        }),
        tap((total) => {
          this.updateTotal(total);
        })
      )
      .subscribe();
      
*/
    this.selectCart()
      .pipe(
        switchMap((cart) => {
          if (cart.lines && cart.lines.length > 0) {
            return this.rm
              .post('basket/orderDTODraft', cart)
              .pipe(catchError(() => EMPTY));
          } else {
            return of({}).pipe(delay(2));
          }
        }),
        tap((response: any) => {
          if (!response) return;
          response as IOrderDTO;
          if (response.lines) {
            cartStore.update(
              setProps({
                orderDTO: new OrderDTO(response),
                totalPrice: response.total,
              })
            );
          } else {
            cartStore.update(
              setProps({
                orderDTO: {},
                totalPrice: {
                  amount_gross: '0',
                  amount_net: '0',
                  amountGross: 0,
                  amountNet: 0,
                  currency: 'EUR',
                  taxes: [],
                  tax_total: '0',
                },
              })
            );
          }
        }),
        catchError(() => {
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe();
    this.actions
      .pipe(
        ofType(proposeDefaultAddress),
        tap((action) => {
          this.setDefaultAddresses(action);
        })
      )
      .subscribe();
    this.actions
      .pipe(
        ofType(proposePhoneNumber),
        tap((action) => {
          let data = cartStore.getValue().data ?? {};
          data.phone = action.phone;
          this.setData(data);
        })
      )
      .subscribe();
  }

  setDeliveryDate(date: Date | null | undefined) {
    cartStore.update(setProps({ deliveryDate: date }));
  }
  setAvailableZipCodes(zipCodes: string[]) {
    this.zipCodes = zipCodes;
  }
  setTotalDiscount(discount: number | string) {
    cartStore.update(setProps({ totalDiscount: Utils.getNumber(discount) }));
  }

  selectOrderDTO() {
    return cartStore.pipe(select((state) => state.orderDTO));
  }

  setShipmentOption(shipment_option: any) {
    cartStore.update(setProps({ shipmentOption: shipment_option }));
  }

  setCustomerNote(customer_note: any) {
    cartStore.update(setProps({ customer_note }));
  }
  selectShipmentOption() {
    return cartStore.pipe(select((state) => state.shipmentOption));
  }

  setRequiredFields(required_fields: string[]): void {
    this.requiredFields = required_fields;
  }

  initLiveTotalCalculation() {
    return this.selectCart().pipe(
      mergeMap((cart) => {
        if (cart.lines && cart.lines.length > 0) {
          return this.rm.post('basket/total', cart).pipe(
            tap((value: any) => {
              cartStore.update(setProps({ totalPrice: value }));
            })
          );
        } else {
          cartStore.update(
            setProps({
              totalPrice: {
                amount_gross: '0',
                amount_net: '0',
                amountGross: 0,
                amountNet: 0,
                currency: 'EUR',
                taxes: [],
                tax_total: '0',
              },
            })
          );
          return EMPTY;
        }
      })
    );
  }

  getCalculatedTotalLive() {
    return cartStore.pipe(
      auditTime(0),
      select((cart) => cart.totalPrice)
    );
  }

  addLineItemToCart(
    amountNet: number,
    amountGross: number,
    name: string,
    quantity: number
  ) {
    let lines = cartStore.query(getAllEntities());
    let nextId = cartStore.getValue().latestId + 1;
    cartStore.update(
      addEntities({ id: nextId, amountNet, amountGross, name, quantity }),
      setProps({ latestId: nextId })
    );
    this.emitUpdatedItems();
  }

  addLineToCart(line: ICartLine) {
    let lines = cartStore.query(getAllEntities());
    let nextId = cartStore.getValue().latestId + 1;
    cartStore.update(
      addEntities({ ...line, id: nextId }),
      setProps({ latestId: nextId })
    );
    this.emitUpdatedItems();
  }

  addProductQuantity(product: IProduct, diffQuantity: number) {
    let newQuantity;
    if (isString(diffQuantity)) {
      newQuantity = Number.parseFloat(diffQuantity);
      diffQuantity = newQuantity;
    } else {
      newQuantity = diffQuantity;
    }

    let lines = cartStore.query(getAllEntities());
    if (product.stackable || product.stackable == undefined) {
      for (let line of lines) {
        if (!isNumber(line.quantity)) {
          line.quantity = Number.parseFloat(line.quantity);
        }
        if (line.productId == product.id && line.id) {
          newQuantity = line.quantity + diffQuantity;
        }
      }
      return this.updateProductQuantity(product, newQuantity);
    }

    return this.updateProductQuantity(product, newQuantity);
  }

  getProductQuantityById(productId: number) {
    let lines = cartStore.query(getAllEntities());
    let productLine = lines.find((line) => line.productId == productId);
    return productLine?.quantity ?? 0;
  }

  updateProductQuantity(
    product: IProduct,
    quantity: number,
    options: any = {}
  ) {
    let lines = cartStore.query(getAllEntities());
    let highestId = lines.reduce((prev, line: any) => {
      return prev < line.id ? line.id : prev;
    }, 1);
    let localProduct = cloneDeep(product);
    if (!localProduct.price.amountNet || !localProduct.price.amountGross) {
      localProduct.price.amountNet = Number.parseFloat(
        localProduct.price.amount_net
      );
      localProduct.price.amountGross = Number.parseFloat(
        localProduct.price.amount_gross
      );
    }

    if (localProduct.stackable == undefined) {
      localProduct.stackable = true;
    }
    if (localProduct.stackable) {
      for (let line of lines) {
        if (line.productId == localProduct.id && line.id) {
          let newLine = {
            ...line,
            quantity: quantity,
          };
          if (quantity > 0) {
            cartStore.update(updateEntities(line.id, newLine));
          } else {
            cartStore.update(deleteEntities(line.id));
          }

          this.emitUpdatedItems();
          this.afterAdd$.next(line.id);
          return;
        }
      }
    }

    let nextId = cartStore.getValue().latestId + 1;
    let newLine: any = {
      id: nextId,
      amountNet: localProduct.price.amountNet,
      amountGross: localProduct.price.amountGross,
      name: localProduct.name,
      productId: localProduct.id,
      price: localProduct.price,
      product: product,
      quantity,
    };
    if (options.undeletable) {
      newLine.undeletable = true;
    }
    if (quantity > 0) {
      cartStore.update(addEntities(newLine), setProps({ latestId: nextId }));
    } else {
    }

    this.emitUpdatedItems();
    this.afterAdd$.next(nextId);
  }
  updateLine(line: ICartLine) {
    if (line.id) {
      cartStore.update(updateEntities(line.id, line));
    }
    this.emitUpdatedItems();
  }

  updateQuantityByLineId(lineId: number, quantity: number) {
    if (isString(quantity)) {
      quantity = Number.parseFloat(quantity);
    }
    cartStore.update(updateEntities(lineId, (line) => ({ ...line, quantity })));
    this.emitUpdatedItems();
  }

  removeLine(line: ICartLine) {
    cartStore.update(deleteEntities(line.id));
    this.emitUpdatedItems();
  }
  clearCart() {
    emitOnce(() => {
      cartStore.update(deleteAllEntities(), setProp('vouchers',[]));
      
      cartStore.reset();
    })
    
    this.emitUpdatedItems();

    //  this.cartStore.reset();
  }

  createOrderForCart() {
    return combineLatest([
      this.selectCart(),
      cartStore.pipe(select((state) => state.orderDraft)),
    ]).pipe(
      take(1),
      mergeMap(([cart, orderDraft]): any => {
        if (orderDraft?.id) {
          return of();
        }
        return this.rm.post('order', cart).pipe(
          tap((order: any) => {
            emitOnce(() => {
              this.resetCart();
              this.clearCart();
            });
            //cartStore.update(setProps({ orderDraft: order}));
          })
        );
      })
    );
  }

  resetCart() {
    cartStore.update(
      setProps({
        shippingAddress: undefined,
        billingAddress: undefined,
        paymentMethod: undefined,
        shippingMethod: undefined,
      }),
      deleteAllEntities()
    );
  }

  selectOrderDraft() {
    return cartStore.pipe(select((state) => state.orderDraft));
  }

  getCart(): ICart {
    return {
      lines: cartStore.query(getAllEntities()),
      shippingAddress: cartStore.getValue().shippingAddress,
      paymentMethod: cartStore.getValue().paymentMethod,
      billingAddress: cartStore.getValue().billingAddress,
    };
  }

  getCount(): number {
    return cartStore.query(getEntitiesCount());
  }

  setPaymentMethod(paymentMethod: any) {
    cartStore.update(setProps({ paymentMethod: paymentMethod }));
  }

  selectPaymentMethod() {
    return cartStore.pipe(select((state) => state.paymentMethod));
  }

  isValidCart(): Observable<boolean> {
    return this.selectCart().pipe(
      map((cart) => {
        if (cart.lines.length == 0) {
          return false;
        }
        if (
          this.requiredFields.indexOf('shipping') != -1 &&
          cart.shippingAddress == undefined
        ) {
          return false;
        }
        if (
          this.requiredFields.indexOf('billing') != -1 &&
          cart.billingAddress == undefined
        ) {
          return false;
        }
        if (
          this.requiredFields.indexOf('payment') != -1 &&
          cart.paymentMethod == undefined
        ) {
          return false;
        }
        if (
          this.requiredFields.indexOf('shipmentOption') != -1 &&
          cart.shipmentOption == undefined
        ) {
          return false;
        }
        return true;
      })
    );
  }

  selectCart(): Observable<ICart> {
    return combineLatest([
      cartStore.pipe(
        selectAllEntities(),
        map((lines) =>
          _.sortBy(lines, (line) => line.product?.status ?? 'published')
        )
      ),
      cartStore.pipe(select((state) => state.data)),
      cartStore.pipe(select((state) => state.shippingAddress)),
      cartStore.pipe(select((state) => state.billingAddress)),
      cartStore.pipe(select((state) => state.userId)),
      this.selectShipmentOption(),
      this.selectPaymentMethod(),
      cartStore.pipe(select((state) => state.vouchers)),
      cartStore.pipe(select((state) => state.customer_note)),
      cartStore.pipe(select((state) => state.sameAddress)),
      cartStore.pipe(select((state) => state.deliveryDate)),
      cartStore.pipe(select((state) => state))
    ]).pipe(
      //distinctUntilChanged(_.isEqual),
      map(
        ([
          lines,
          data,
          shippingAddress,
          billingAddress,
          userId,
          shipmentOption,
          paymentMethod,
          vouchers,
          customer_note,
          sameAddress,
          deliveryDate,
          state
        ]) => {
          console.log(lines);
          console.log(vouchers);
          let additionalFields: any = {};
          if (userId) {
            additionalFields.userId = userId;
          }
          if (data) {
            additionalFields.data = data;
          }
          if (customer_note) {
            additionalFields.customer_note = customer_note;
          }
          let body: any = {
            ...additionalFields,
            lines,
            shippingAddress: shippingAddress,
            billingAddress: billingAddress,
          };
          if (sameAddress) {
            body.shippingAddress = body.billingAddress;
          }

          if (shipmentOption) {
            body.shipmentOption = shipmentOption;
          }

          if (paymentMethod) {
            body.paymentMethod = paymentMethod;
          }

          if (vouchers) {
            vouchers = vouchers.map((voucher: any) => voucher.code ?? '');
            body.vouchers = vouchers;
          }
          if (deliveryDate) {
            body.date_preferred_delivery = deliveryDate;
          }

          return body;
        }
      )
    );
  }
  selectCartLines(): Observable<ICartLine[]> {
    return cartStore.pipe(selectAllEntities());
  }

  selectCount(): Observable<number> {
    return cartStore.pipe(selectEntitiesCount());
  }

  selectCountQuantity(): Observable<number> {
    return cartStore.pipe(
      selectAllEntities(),
      map((entities) =>
        entities.reduce((acc, entity) => {
          return acc + entity.quantity;
        }, 0)
      )
    );
  }

  selectTotal(): Observable<number> {
    return cartStore.pipe(select((state) => state.cartTotal));
  }

  calculateTotal(entities: ICartLine[]) {
    let total = 0;
    entities.forEach((entitty) => {
      total += entitty.amountGross * entitty.quantity;
    });

    return total;
  }

  calculateTotalAsPrice(entities: ICartLine[]) {
    let totalPrice: IPrice & { amountGross: number; amountNet: number } = {
      amount_gross: '0',
      amount_net: '0',
      amountGross: 0,
      amountNet: 0,
      currency: 'EUR',
      taxes: [],
      tax_total: '0',
    };

    entities.forEach((entitty) => {
      totalPrice.amountGross += entitty.amountGross * entitty.quantity;
      totalPrice.amountNet += entitty.amountNet * entitty.quantity;
    });

    return totalPrice;
  }

  selectTotalCalculated(): Observable<number> {
    return cartStore.pipe(
      selectAllEntities(),
      map((entities) => this.calculateTotal(entities))
    );
  }

  selectTotalCalculatedPriceEntity(): Observable<IPrice> {
    return cartStore.pipe(
      selectAllEntities(),
      map((entities) => this.calculateTotalAsPrice(entities))
    );
  }

  updateTotal(total: number) {
    cartStore.update(setProps({ cartTotal: total }));
  }
  setAddresses(
    billingAddress: IAddress,
    shippingAddress: IAddress | undefined = undefined
  ) {
    cartStore.update(setProps({ billingAddress, shippingAddress }));
    this.actions.dispatch(
      addressesChanged({ billingAddress, shippingAddress })
    );
  }
  setDefaultAddresses(addresses: {
    shippingAddress: IAddress | undefined;
    billingAddress: IAddress | undefined;
  }) {
    emitOnce(() => {
      if (
        addresses.shippingAddress &&
        cartStore.getValue().shippingAddress == undefined
      ) {
        cartStore.update(
          setProps({ shippingAddress: addresses.shippingAddress })
        );
      }
      if (
        addresses.billingAddress &&
        cartStore.getValue().billingAddress == undefined
      ) {
        cartStore.update(
          setProps({ billingAddress: addresses.billingAddress })
        );
      }
      let sameAddress = false;
      if (
        (addresses.billingAddress?.id ?? 1) ==
        (addresses.shippingAddress?.id ?? 2)
      ) {
        cartStore.update(setProps({ sameAddress: true }));
        sameAddress = true;
      }
      this.actions.dispatch(
        addressesChanged({
          billingAddress: addresses.billingAddress,
          shippingAddress: addresses.shippingAddress,
          sameAddress: sameAddress,
        })
      );
    });
  }
  cartSameAddress(sameAddress: boolean) {
    cartStore.update(setProps({ sameAddress }));
  }

  getBillingAddress() {
    return cartStore.getValue().billingAddress;
  }

  getShippingAddress() {
    return cartStore.getValue().shippingAddress;
  }
  setData(data: any) {
    cartStore.update(setProp('data', (old: any) => ({ ...old, ...data })));
    this.actions.dispatch(dataChanged({ data: data }));
  }
  setDataKey(key: string, value: any) {
    cartStore.update(
      setProp('data', (old: any) => {
        let data = { ...old };
        data[key] = value;

        // this.actions.dispatch(dataChanged({data:data}));
        return data;
      })
    );
  }

  getPaymentUrlForOrder() {
    return cartStore.pipe(
      select((state) => state.orderDraft),

      take(1),
      mergeMap((order) => {
        if (!order) {
          return EMPTY;
        }

        return this.rm.get('/order/' + order['id'] + '/payment').pipe(
          map((res: any) => {
            return res.url;
          })
        );
      })
    );
  }

  setUserId(id: number) {
    cartStore.update(setProps({ userId: id }));
  }

  emitUpdatedItems() {
    this.actions.dispatch(updatedItems());
  }

  selectVouchers() {
    return cartStore.pipe(select((state) => state.vouchers));
  }

  removeVoucher(voucher: { id: any }) {
    
    cartStore.update(setProp('vouchers', (vouchers) => {
      
      let found = vouchers.findIndex((v: any) => v.id == voucher.id);
      vouchers.splice(found, 1);
      return vouchers;
    }));
    this.emitUpdatedItems();
  }

  addVoucher(voucherCode: string) {
    return this.rm.get('voucherCheck/' + voucherCode).pipe(
      map((res: any) => {
       
        cartStore.update(setProp('vouchers', (vouchers) => {
          let newV = vouchers;
          if (newV.find((v) => v.id == res.id) != undefined) {
            throw new Error('error duplication');
          }
          newV.push(res);
          return newV;
        }));
        this.emitUpdatedItems();
        return res;
      })
    );
  }
}
