import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GtagService } from '@app/google-analytics';
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private GtagService: GtagService) {
    this.route.params.subscribe((params) => {
      if (params['id'] == 'about') {
        this.content = this.about;
      }
      if (params['id'] == 'userConfirmed') {
        this.content = this.confirmed;
      }
    });
  }

  confirmed = `
   <h4>Benutzer bestätigt</h4>
   <p>Sie können sich jetzt einloggen und Ihre Bestellung abschließen.</p>`;

  about = `<h4>Über uns</h4>
  <div class="grid1">
  <img class="col1" src="/assets/img/about-us/image00013.png">
  <p>Vom Anbau des Getreides über die Schweinehaltung, der hauseigenen
  Fleischverarbeitung sowie Wurstproduktion bis hin zur traditionellen
  frischen Küche mit regionaler Hausmannskost, betreiben wir
  zur Gänze alles aus einer Hand.
  Somit können wir täglich stolz mit bester Qualitätund frischer Ware
  aus der Region punkten</p>
  <img class="col2" src="/assets/img/about-us/image00001.jpeg">
  </div>
  <h5>Regionalität vom Acker bis zum Teller!</h5>
  
  <div class="grid2">
  <img src="/assets/img/about-us/image00010.jpeg">
  <p>Wir, die Familie Groß mit Bauernhof,
Äcker aus dem Feistritztal in
der Nähe von Stubenberg am See,
produzieren wie dazumal nach
bäuerlicher Tradion mit Liebe zum
Produkt.</p>
<img src="/assets/img/about-us/image00011.jpeg">
<p>Mit ca. 200 % mehr Platz und Auslauf
als herkömmlicheTierhaltung,
und hofeigenem Futter fühlen sich
alle Tiere „sauwohl“. <br><br>
Diese artgerechte und gentechnikfreie
Fütterung ist entscheidend für ein gesundes
und natürliches und langsames
Wachstum der Tiere und wirkt sich
besonders posiv auf der Fleischqualität aus.</p>
<img  src="/assets/img/about-us/image00008.jpeg">
<p>Alle Würste werden von Hand
gefüllt und im Kessel gekocht!
Unser Fleisch wird noch von Hand
gesalzen und über Buchenspänen
in der Holzselch geräuchert.</p>
<img  class="span2" src="/assets/img/about-us/image00012.jpeg">
<img src="/assets/img/about-us/image00006.jpeg">
</div>
`;
  content;
  ngOnInit(): void {}
}
