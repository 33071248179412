<mat-card>
  <h4>Kühl Lebensmittelversand</h4>
  <h5>mit Next Day Post Zustellung</h5>
  <img src="/assets/img/versandbox.png" />
  <div class="tableRate">
    <table>
      <tr>
        <td>Bestellwert</td>
        <td>Versandkosten</td>
      </tr>
      <tr>
        <td>ab 99€</td>
        <td>Versandkostenfrei</td>
      </tr>
      <tr>
        <td>ab 50€</td>
        <td>4,90€</td>
      </tr>
      <tr>
        <td>bist 50€</td>
        <td>9,90€</td>
      </tr>
    </table>
  </div>
  <div>
    Bestellungen die bis 10 Uhr bei uns eingehen, werden am selben Tag von der
    Post abgeholt und sind am nächsten Tag bei Ihnen zu Hause.
    <br />
    Die Anliefertage sind Dienstags bis Freitag. Versandt wird nur von Montag
    bis Donnerstag.
    <br />
    Bestellungen die ab Donnerstag 10 Uhr eingehen werden Montag versandt und
    Dienstag geliefert.
  </div>
  <div>
    <h4>Hochmoderene Kühlboxen garantieren Frische</h4>
    <p>
      Die Kühlboxen wurden eigens für den Kühlversand entwickelt. Tausendfach
      erprobt und garantieren Frische bis zur Haustüre.
    </p>
    <h4>Kühblox Rückversand kostenlos!</h4>
    <p>
      Wir bitten Sie wenn möglich die Kühlbox samt der beiligenden Akkus gleich
      bei Lieferung dem Zusteller wieder mitzugeben.
    </p>
    <p>
      Falls dies nicht möglich ist, bringen Sie die Kühlbox zur Post bzw. einem
      Postpartner bringen, oder dem Postboten am nächsten Tag wieder mitgeben.
    </p>
    <p>
      Kleben Sie unbedingt das <strong>Rücksendeetikett</strong> auf die Kühlbox
    </p>
  </div>
  <div>
    <p>
      Wenn die Kühlbox samt Akkus nicht innerhalb von 14 Tagen retourniert wird,
      erlauben wir uns Ihnen 99€ für die Kühlbox und 20€ für die quadratischen
      Kühlakkus in Rechnung zu stellen.
    </p>
  </div>
  <div>
    <p>
      Sind Sie bei Anlieferung der Kühlbox nicht zuhause, wird das Paket vor
      Ihrer Haustüre abgestellt.
    </p>
  </div>
  <div>
    <h5>Mindestbestellwert 15€</h5>
  </div>
  <div>
    <p>
      Im Regelfall wird die Bestellung, wenn Sie im Zeitraum von Mo-Do bis 10
      Uhr aufgegeben wird, dann wird diese mit Post Next Day am nächsten Tag,
      spätestens jedoch innerhalb 3 Werktage geliefert.
    </p>
  </div>
</mat-card>
