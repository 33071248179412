import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { hasTouch, detectTouch } from 'detect-touch';
import { Router } from '@angular/router';
import { CartService } from '@pisci/data-cart';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit {
  touch = hasTouch;

  constructor(
    private router: Router,
    public cartService: CartService,
    private menu: MenuController
  ) {
    this.cartCount$ = cartService.selectCount();
  }

  cartCount$;

  ngOnInit(): void {}
  clickedLeitbild() {
    this.router.navigateByUrl('/');
  }
  cartClicked() {
    this.menu.toggle('cart');
  }

  goToCheckoutClicked() {
    this.router.navigateByUrl('checkout');
  }

  clickedLogo() {
    this.router.navigateByUrl('/');
  }

  clickedShop() {
    this.clickedLogo();
  }
  clickedGrossGenuss() {
    window.open('https://www.gross-genuss.at');
  }
  clickedAboutUs() {
    this.router.navigateByUrl('page/about');
  }
  clickedShipping() {
    this.router.navigateByUrl('shipping');
  }
}
