import { Injectable } from '@angular/core';
import { IPrice, IProduct } from '@app/data-interfaces';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import { ICart, ICartLine } from './cart.interface';

export interface IOrder {
  id?:number,
  billing_address?:IAddress,
  shipping_address?:IAddress,
  shipping_total:string,
  total:string,
  discount_total:string,

  [key: string]: any;
}
export interface IAddress {
  name: string;
  street: string;
  street_2: string;
  zip: string;
  city: string;
}
export interface CartEntityState extends EntityState<ICartLine, number> {
  cartTotal: number;
  totalPrice?:IPrice;
  orderDraft: IOrder|any;
  shippingAddress?: IAddress;
  billingAddress?: IAddress;
  userId?: number;
  customerId?: number;
  data?: any;
  totalDiscount?:number;
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cart', resettable: true })
export class CartStore extends EntityStore<CartEntityState> {
  constructor() {
    super({ cartTotal: 0, orderDraft: {} });
  }

  updateQuantity(product: IProduct, operand = 1) {
    this.update(product.id, (entity) => {
      const newQuantity = entity.quantity + operand;
      return {
        ...entity,
        quantity: newQuantity,
      };
    });
  }

  updateOrderDraft(order: IOrder) {
    this.update({ orderDraft: order });
  }
}
