import { isString } from "lodash";

export class Utils {
  static  getNumber(value: number | string| undefined): number {
    if ( value == undefined ) {
      return 0;
    }
    if (isString(value)) {
      value = Number.parseFloat(value);
    }
    return value;
  }
  static cleanNumber(value: number, precision: number =2): number {
    var f = Math.pow(10, precision);
    return Math.round(value * f) / f;
  }

  static UUID() {
    var rand = Math.random;
      var nbr, randStr = "";
      do {
          randStr += (nbr = rand()).toString(16).substr(3, 6);
      } while (randStr.length < 30);
      return (
          randStr.substr(0, 8) + "-" +
          randStr.substr(8, 4) + "-4" +
          randStr.substr(12, 3) + "-" +
          ((nbr*4|0)+8).toString(16) + // [89ab]
          randStr.substr(15, 3) + "-" +
          randStr.substr(18, 12)
      );
  }
}