<ion-menu side="end" menuId="cart" contentId="mainOutlet">
  <ion-content>
    <ui-mini-cart (checkoutClicked)="goToCheckoutClicked()">
      <div class="information">
        Wir bieten Ihnen folgende Zahlungsmöglichkeiten an:
        <ul>
          <li>Kreditkarte</li>
          <li>SOFORT Überweisung</li>
        </ul>
      </div>
      <div class="shipping_information">
        <h5>Versandkosten</h5>
        <table>
          <tr>
            <td>ab 99€</td>
            <td>Versandkostenfrei</td>
          </tr>
          <tr>
            <td>ab 50€</td>
            <td>4,90€</td>
          </tr>
          <tr>
            <td>bis 50€</td>
            <td>9,90€</td>
          </tr>
        </table>

        <hr />
        <p>Mindestbestellwert: 15€</p>
      </div>
    </ui-mini-cart>
  </ion-content>
</ion-menu>

<ion-content id="mainOutlet" [scrollY]="true">
  <ng-container *ngIf="cartCount$ | async as cartCount">
    <ion-fab
      *ngIf="cartCount > 0"
      vertical="bottom"
      horizontal="end"
      slot="fixed"
    >
      <ion-fab-button (click)="cartClicked()">
        <ion-icon name="cart"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ng-container>
  <div class="defaultContainer" [class]="{ touch: touch }">
    <div class="headerContainer container">
      <div class="row">
        <div class="hero">Heute Bestellen - Morgen genießen!</div>
      </div>
      <div class="row mainRow">
        <div class="line1">
          <div (click)="clickedLogo()" class="logo clickable">
            Bauernshop Groß
          </div>
        </div>
        <div class="line2">
          <div class="refLink clickable" (click)="clickedShop()">Shop</div>
          <div class="refLink clickable" (click)="clickedGrossGenuss()">
            Gross Genuss
          </div>
          <div class="refLink" (click)="clickedAboutUs()">Über Uns</div>
          <!--div class="menu-expander">
            <ion-icon name="menu-outline"></ion-icon>
          </div>

          <div class="search icon"><ion-icon name="search"></ion-icon></div-->
          <div class="shipping icon clickable" (click)="clickedShipping()">
            <ion-icon name="cube"></ion-icon><span>Versand</span>
          </div>
          <div class="cart icon clickable" (click)="cartClicked()">
            <ng-container *ngIf="cartCount$ | async as cartCount"
              ><ion-badge *ngIf="cartCount > 0">{{
                cartCount
              }}</ion-badge></ng-container
            >
            <ion-icon name="cart"></ion-icon><span>Warenkorb</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="hero">Handwerksqualität - Einkaufen wo’s wächst</div>
      </div>
    </div>

    <div (click)="clickedLeitbild()" class="leitbild">
      <img src="/assets/img/leitbild2.jpg" />
    </div>

    <div class="main container">
      <router-outlet></router-outlet>
    </div>

    <div class="footer container">
      <div>
        <ng-container *ngIf="cartCount$ | async as cartCount"
          ><div
            *ngIf="cartCount > 0"
            class="cartButton"
            (click)="cartClicked()"
          >
            <ion-icon name="cart"></ion-icon><span>Bestellung abschließen</span>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ion-content>
