import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GtagService } from '@app/google-analytics';
import { CartService } from '@pisci/data-cart';
import { OrderService } from '@pisci/data-orders';
import { distinctUntilChanged, take } from 'rxjs/operators';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'GrossShop';
  public constructor(private gtagService: GtagService, private router: Router, private cartService: CartService, private orderService: OrderService) {
    router.events
      .pipe(
        distinctUntilChanged((previous: any, current: any) => {
          // Subscribe to any `NavigationEnd` events where the url has changed
          if (current instanceof NavigationEnd) {
            return previous.url === current.url;
          }
          return true;
        })
      )
      .subscribe((event: NavigationEnd) => {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
      });
  }
  ngOnInit(): void {
    let id = this.cartService.getOrder().id ?? null;
    if(id) {
      this.orderService.getOrder(id).pipe(take(1)).subscribe(order => {
        if(order['status'] != 'draft') {
          this.cartService.clearCart();
        }
      })
      this.orderService.loadOrder(id).subscribe();
    }
  }
}
