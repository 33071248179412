import { isString } from "@ngneat/elf";
import { isNumber } from "lodash";

export interface IPrice {
  id?: number;
  amount_net: string;
  amount_gross: string;
  amountGross?: number;
  amountNet?: number;
  currency: string;
  tax_total: string;
  taxes: ITax[];
}

export interface ITax {
  id?: number;
  tax: string | number;
  amountNet?: number;
  taxgroups_id: number;
  taxgroup: ITaxgroup;
  amount_net?:string;
  
}

export interface ITaxgroup {
  id?: number;
  tax: number | string;
  name?: string;
  label: string;
}

export class Tax implements ITax {
  public get id(): number|undefined {
    return this.taxObject.id;
  }

  public get tax(): string | number {
    return this.taxObject.tax;
  }

  public get amountNet(): number |undefined{
    return this.taxObject.amountNet;
  }
  public get taxgroups_id(): number {
    return this.taxObject.taxgroups_id;
  }

  public get taxgroup(): ITaxgroup {
    return this.taxObject.taxgroup;
  }

  public get amount_net(): string |undefined {
    return this.taxObject.amount_net;
  
  }

  getAmountNet(): number {
    if (this.taxObject.amountNet) {
      return this.taxObject.amountNet;
    } else {
      return Number.parseFloat(this.taxObject.amount_net??'0');
    }
  }

  amountAsNumber?:number;
  
  getAmount(): number {
    if (this.amountAsNumber) {
      return this.amountAsNumber;
    }
    return 0;
  }


  constructor(public taxObject: ITax) {
    if(taxObject.tax && isString(taxObject.tax)) {
      this.amountAsNumber = Number.parseFloat(taxObject.tax);
    } else if(isNumber(taxObject.tax)) {
      this.amountAsNumber = taxObject.tax;
    }
    
  }
}
export class Price implements IPrice {
  id?: number;
  amount_net: string;
  amount_gross: string;
  amountGross?: number;
  amountNet?: number;
  currency: string;
  tax_total: string;
  taxes: Tax[];
  constructor(iprice: IPrice) {
    this.id = iprice.id;
    this.amount_net = iprice.amount_net;
    this.amount_gross = iprice.amount_gross;
    this.amountGross = Number.parseFloat(iprice.amount_gross);
    this.amountNet = Number.parseFloat(iprice.amount_net);
    this.currency = iprice.currency;
    this.tax_total = iprice.tax_total;
    this.taxes = iprice.taxes.map((tax) => new Tax(tax));
  }

  getAmountGross() {
    if (this.amountGross) {
      return this.amountGross;
    } else {
      return Number.parseFloat(this.amount_gross);
    }
  }

  getAmountNet() {
    if (this.amountNet) {
      return this.amountNet;
    } else {
      return Number.parseFloat(this.amount_net);
    }
  }
  getTotalAmountGross(quantity: number) {
    return this.getAmountGross() * quantity;
  }
}
export interface IPriceDTO {
  amountGross: number;
  amountNet: number;
  taxes: ITaxDTO[];
}

export interface ITaxDTO {
  name: string;
  amount: number;
  amountGross: number;
  amountNet: number;
  price_id: number;
  taxrate: string;
  taxgroups_id: number;
}

export interface ILineItemDTO {
  name: string;
  price: IPriceDTO;
  quantity:number;
}

export interface IOrderDTO {
  lines: ILineItemDTO[];
  voucherLines: ILineItemDTO[];
  total: IPriceDTO;
  shippingLine: ILineItemDTO;
  subtotal:IPriceDTO;
}
export class LineItemDTO {
  name: string;
  price: PriceDTO;
  quantity:number;
  constructor(line: ILineItemDTO) {
    this.name = line.name;
    this.price = new PriceDTO(line.price);
    this.quantity = line.quantity;
  }
}

class Priceable {
  constructor(public amountGross: number, public amountNet: number) {}

  getAmountGross(): number {
    return this.amountGross ;
  }

  getAmountNet(): number {
    return this.amountNet;
  }

  
}

export class PriceDTO extends Priceable {
  taxes: TaxDTO[];
  constructor(price: IPriceDTO) {
    super(price.amountGross, price.amountNet);
    this.taxes = price.taxes.map((tax) => new TaxDTO(tax));
  }
  public hasTaxes(): boolean {
    return this.taxes.length > 0;
  }
}

export class TaxDTO extends Priceable {
  name: string;
  amount: number;
  price_id: number;
  taxrate: string;
  taxgroups_id: number;
  taxgroup? : ITaxgroup
  constructor(tax: ITaxDTO) {
    super(tax.amountGross, tax.amountNet);
    this.name = tax.name;
    this.amount = tax.amount;

    this.taxgroups_id = tax.taxgroups_id;
    this.taxrate = tax.taxrate;
    this.price_id = tax.price_id;
  }

  getAmount() {
    return this.amount;
  }
}

export class OrderDTO {
  lines: LineItemDTO[];
  voucherLines: LineItemDTO[]=[];
  shippingLine?: LineItemDTO;
  total: PriceDTO;
  subtotal:PriceDTO;
  constructor(order: IOrderDTO) {
    this.lines = order.lines.map((line) => new LineItemDTO(line));
    this.subtotal = new PriceDTO(order.subtotal);
    if(order.shippingLine) {
      
      this.shippingLine = new LineItemDTO(order.shippingLine);
      this.lines.push(this.shippingLine);
    }
    if(order.voucherLines) {
     this.voucherLines =  order.voucherLines.map((line) => new LineItemDTO(line));
    }
    
    this.total = new PriceDTO(order.total);
  }
}

