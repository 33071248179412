import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiMiniCartComponent } from './ui-mini-cart.component';

import { MatTableModule } from '@angular/material/table';
import { IonicModule } from '@ionic/angular';
@NgModule({
  declarations: [UiMiniCartComponent],
  imports: [CommonModule, MatTableModule, IonicModule],
  exports: [UiMiniCartComponent],
})
export class UiMiniCartModule {}
