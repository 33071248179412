import { Injectable } from '@angular/core';
import { IProducer } from '@app/data-interfaces';
import { RequestService } from '@pisci/requestManager';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProducerService {

  constructor(private rm:RequestService) { }

  getProducers() :Observable<IProducer[]> {
    return this.rm.getAllPages('/producer') as Observable<IProducer[]>;
  }

  getProducer(id: string) : Observable<IProducer>{
    return this.rm.get('/producer/' + id) as Observable<IProducer>;
  }

  upsertProducer(producer: IProducer) {
    if(!producer.id) {
      return this.rm.post('/producer', producer);
    } else {
      return this.rm.put('/producer/' + producer.id, producer);
    } 
  }
  syncProducerToProduct(producer_ids:number[]|string[], product_id:number|string) {
    return this.rm.patch('/product/'+product_id+'/producers/sync', {resources:producer_ids})
  }
}
