import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CartService } from '@pisci/data-cart';
import { ICart, ICartLine } from '@pisci/data-cart';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: ICartLine[] = [];

@Component({
  selector: 'ui-mini-cart',
  templateUrl: './ui-mini-cart.component.html',
  styleUrls: ['./ui-mini-cart.component.scss'],
})
export class UiMiniCartComponent implements OnInit, OnDestroy {
  @Output()
  checkoutClicked = new EventEmitter();

  total = 0;

  subscription: any;
  constructor(public cartService: CartService, public cd: ChangeDetectorRef) {}
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  displayedColumns: string[] = [
    'id',
    'name',
    'quantity',
    'amountGross',
    'buttons',
  ];
  dataSource = ELEMENT_DATA;

  ngOnInit(): void {
    this.subscription = this.cartService.selectTotal().subscribe((total) => {
      this.total = total;
    });
    this.cartService.selectCart().subscribe((cart: ICart) => {
      this.dataSource = cart.lines;
    });
  }

  removeLineClicked(line: ICartLine) {
    this.cartService.removeLine(line);
  }

  goToCheckoutClicked() {
    this.checkoutClicked.next(true);
  }
}
