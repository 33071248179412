<div [hidden]="(productLoading$ | async) === true">
  <div *ngIf="product$ | async as productOriginal">
    <div class="categoryProductDetail">
      <div routerLink="/category">Zurück zum Shop</div>
      <div
        *ngFor="let category of productOriginal.categories"
        (click)="clickCategory(category.id)"
      >
        {{ category.name }}
      </div>
    </div>
    <div class="productDetail" *ngIf="selectedProduct as product">
      <div class="title">{{ product.name }}</div>
      <div class="image" *ngIf="product.images.length > 0">
        <img [src]="getProductImage(product)" />
      </div>
      <ng-container *ngIf="!hasVariations">
        <h4 class="weights" *ngIf="product.data.weight">Gewicht</h4>
        <div class="weights">
          <div>{{ product.data.weight }}</div>
        </div>
      </ng-container>
      <h4 *ngIf="hasVariations" class="weights">Gewicht Auswählen</h4>
      <div *ngIf="hasVariations" class="weights">
        <div
          [class.active]="isActiveProduct(productOriginal)"
          (click)="clickVariation(productOriginal)"
        >
          {{ productOriginal.data.weight }}
        </div>
        <div
          [class.active]="isActiveProduct(variation)"
          (click)="clickVariation(variation)"
          *ngFor="let variation of productOriginal.variations"
        >
          {{ variation.data.weight }}
        </div>
      </div>
      <div>
        <div *ngIf="product.status == 'out_of_stock'" class="price_wrapper">
          <mat-card>Produkt im Moment nicht verfügbar.</mat-card>
        </div>
        <div *ngIf="product.status !== 'out_of_stock'" class="price_wrapper">
          <div class="price">
            {{ getProductPrice(product) | number: '1.2' }}&nbsp;€
          </div>
          <div class="add_to_cart">
            <div>
              <ion-icon
                name="caret-up-outline"
                (click)="increaseCartClicked()"
              ></ion-icon>
              <ion-icon
                name="caret-down-outline"
                (click)="decreaseCartClicked()"
              ></ion-icon>
            </div>
            <div>
              <ion-input type="number" [(ngModel)]="cartAddAmount"></ion-input>
            </div>
            <div>
              <ion-button
                color="primary"
                (click)="addToCartClicked(selectedProduct)"
                >Zum Warenkorb</ion-button
              >
            </div>
          </div>
        </div>
        <div class="unitPrice" *ngIf="product.data.unit">
          {{ getProductUnitPrice(product) }}
        </div>
        <div class="description" [innerHtml]="product.description"></div>

        <div
          class="ingredients"
          *ngIf="product.data.ingredients"
          class="ingredients"
        >
          <h4>Zutaten</h4>
          {{ product.data.ingredients }}
        </div>
      </div>
    </div>
  </div>
</div>
