<mat-card *ngIf="!cancelled">
  <h4>Vielen Dank für Ihre Bestellung</h4>
  <div>Sie bekommen per Email eine Bestätigung Ihrer Bestellung zugesandt.</div>
</mat-card>

<mat-card *ngIf="cancelled">
  <h4>Bezahlvorgang abgebrochen</h4>
  <div>
    Der Bezahlvorgang wurde abgebrochen, bitte tätigen Sie Ihre Bestellung
    erneut.
  </div>
</mat-card>
