import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IProduct } from '@app/data-interfaces';
import { CartService } from '@pisci/data-cart';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import { ToastController } from '@ionic/angular';
@Component({
  selector: 'app-ui-product-detail',
  templateUrl: './ui-product-detail.component.html',
  styleUrls: ['./ui-product-detail.component.scss'],
})
export class UiProductDetailComponent implements OnInit {
  cartAddAmount = 1;
  hasVariations = false;
  selectedProduct = null;
  
  product$;
  productLoading$;
  constructor(
    private route: ActivatedRoute,
    private cartService: CartService,
    private catalogService: ProductCatalogService,
    private toastController: ToastController,
    private router: Router
  ) {
    this.productLoading$ = this.catalogService.isLoadingProduct();
    this.product$ = this.catalogService.selectProductActive().pipe(
      tap((product) => {
        if (product.variations.length > 0) {
          this.hasVariations = true;
        }
        this.selectedProduct = product;
      })
    );
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.catalogService.setActiveProduct(params['id']);
        this.catalogService.loadProductActive(params['id']);
        /*this.product$ = of(this.products[params['id']]).pipe(
          map((product) => {
            product.description = product.description.replace(
              /(?:\r\n|\r|\n)+/g,
              '<br>'
            );
            product.price.amountGross = Number.parseFloat(
              product.price.amount_gross
            );
            return product;
          })
        );
        */
      } else {
      }
    });
  }

  increaseCartClicked() {
    this.cartAddAmount++;
  }
  decreaseCartClicked() {
    if (this.cartAddAmount > 1) {
      this.cartAddAmount--;
    }
  }

  async addToCartClicked(product) {
    this.cartService.updateProductQuantity(product, this.cartAddAmount);
    const toast = await this.toastController.create({
      color: 'primary',
      message: 'Produkt wurde zum Warenkorb hinzugefügt.',
      duration: 2000,
    });
    toast.present();
  }
  ngOnInit(): void {}

  getProductImage(product) {
    if (
      !(product.images && product.images.length > 0 && product.images[0].path)
    ) {
      return 'https://via.placeholder.com/300x300';
    }
    return 'https://api.logistikmanager.pisci.eu/' + product.images[0].path;
  }

  getProductPrice(product) {
    return product.price.amount_gross;
  }

  getProductUnitPrice(product) {
    if (product.data.units > 100 && product.data.unit == 'g') {
      return (
        ((product.price.amount_gross / product.data.units) * 100).toFixed(2) +
        ' €/100g'
      );
    }
    if (product.data.unit == 'ml') {
      return (
        ((product.price.amount_gross / product.data.units) * 100).toFixed(2) +
        ' €/100mL'
      );
    }
    return (
      (product.price.amount_gross / product.data.units).toFixed(2) +
      ' €/' +
      product.data.unit
    );
  }

  clickVariation(variation) {
    this.selectedProduct = variation;
  }

  clickCategory(categoryId) {
    this.router.navigateByUrl('category/' + categoryId);
  }
  isActiveProduct(product) {
    return this.selectedProduct.id == product.id;
  }
}
