import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { hasTouch, detectTouch } from 'detect-touch';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCatalogService } from '@app/shared/data-productscatalog';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  animateChild,
  // ...
} from '@angular/animations';
import { map, mergeMap, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  animations: [
    trigger('dumbParent', [
      transition('* => void', [
        query('@*', [animateChild()], { optional: true }),
      ]),
    ]),
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'scaleY(1)' })),
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('0.6s ease-out', style({ transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        style({ marginTop: 0 }),
        animate('0.6s ease-out', style({ marginTop: -4000 })),
      ]),
    ]),

    trigger('LeaveEnter', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('0.6s ease-in', style({ transform: 'translateY(0)' })),
      ]),
    ]),

    // animation triggers go here
  ],
})
export class ProductListComponent implements OnInit, OnDestroy {
  touch = hasTouch;
  isList = true;
  selectedCategory = {};

  categories$;

  subscription: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private catalogService: ProductCatalogService
  ) {
    
    this.products$ =   this.route.params.pipe(mergeMap( params => this.catalogService.selectProductsForCategory(params['id'])), tap( products => {
      console.log(products)
    }));
    this.categories$ = this.catalogService.selectOrderedCategories().pipe(
      map((categories) => {
        let cats:any[] = cloneDeep(categories);
        cats.forEach((cat) => {
          cat.img = 'cat' + cat.id + '.jpeg';
        });
        return cats;
      })
    );
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.isList = false;
        this.catalogService.getProductsForCategory(params['id']);
        this.categories$.subscribe((categories: any[]) => {
          this.selectedCategory = categories.filter((cat) => {
            return cat.id == params['id'];
          })[0];
        });
      } else {
        this.isList = true;
      }
    });
  }

  products$;

  ngOnInit(): void {
    this.catalogService.loadProducts().subscribe();
    this.subscription = this.catalogService.loadCategories().subscribe();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getProductImage(product) {
    if (
      !(product.images && product.images.length > 0 && product.images[0].path)
    ) {
      return 'https://via.placeholder.com/300x300';
    }
    return 'https://api.logistikmanager.pisci.eu/' + product.images[0].path;
  }
  getCategoryBackgroundImageUrl(category) {
    return '/assets/img/categories/' + category.img;
  }

  getProductPrice(product) {
    return product.price.amount_gross;
  }

  clickCategory(category) {
    this.router.navigateByUrl('category/' + category.id);
  }
  clickSelectedCategory() {
    this.router.navigateByUrl('category');
  }

  clickProduct(product) {
    this.router.navigateByUrl('product/' + product.id);
  }
}
