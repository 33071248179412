<div class="wrapper" *ngIf="isList" [@dumbParent]>
  <div class="categories mainList" [@EnterLeave]="'flyIn'">
    <div
      class="category"
      *ngFor="let cat of categories$ | async"
      (click)="clickCategory(cat)"
    >
      <img class="img" [src]="getCategoryBackgroundImageUrl(cat)" />
      <div class="text">{{ cat.name }}</div>
    </div>
  </div>
</div>
<div class="wrapper" *ngIf="!isList" [@dumbParent]>
  <div (click)="clickSelectedCategory()" class="selectedCategory">
    <div class="icon"><ion-icon name="chevron-back-outline"></ion-icon></div>
    <div
      class="img"
      [style.backgroundImage]="
        'url(' + getCategoryBackgroundImageUrl(selectedCategory) + ')'
      "
    ></div>
    <div class="text">{{ selectedCategory.name }}</div>
  </div>
  <div class="products mainList" [@LeaveEnter]>
    <div
      class="product"
      (click)="clickProduct(product)"
      *ngFor="let product of products$ | async"
    >
      <img class="img" [src]="getProductImage(product)" />
      <div class="info">
        <span>{{ product.name }}</span>
        <span>{{ getProductPrice(product) | number: '1.2' }} €</span>
      </div>
    </div>
  </div>
</div>
