import { ILineItem, IPrice, IProduct, LineItem, Price } from "@app/data-interfaces";
import * as _ from "lodash";
import { IsEqualCustomizer } from "lodash";

export interface ICartLine {
  id: number;
  quantity: number;
  name: string;
  amountNet: number;
  amountGross: number;
  productId?: number;
  price?:IPrice
  discount?:number,
  product?:IProduct,
  voucherCode?:string;
  undeletable?:boolean;
}

export interface ICart {
  lines: ICartLine[];
  shippingAddress?:IAddress;
  billingAddress?:IAddress;
  paymentMethod?:string;
  shipmentOption?:any;
}
export interface IOrder {
  id?:string;
  number?:string;
  created_at?:string;
  customer_note:string;
  customer?:ICustomer;
  total_price:IPrice;
  billing_address?:IAddress;
  shipping_address?:IAddress;
  line_items?:ILineItem[];
  [key: string]: any;
}
export interface IAddress {
  id?:string;
  name: string;
  street: string;
  street_2: string;
  zip: string;
  city: string;
  country?:string;
  street_number:string;
}

export interface ICustomer {
  firstname:string;
  lastname:string;
  email:string;
  phone:string;
}


export class Order implements IOrder {
  public id:string;
  public totalPrice:Price;
  public line_items:ILineItem[];
  public lineItems:LineItem[];
  public created_at:string;
  public customer_note:string;
  public customer?:ICustomer;
  public billing_address?:IAddress;
  public shipping_address?:IAddress;
  public invoices?:any[];
  public total_price: IPrice;
  [key: string]: any;
  constructor(order:IOrder) {
    _.extend(this,order);
    
    this.total_price = order.total_price;
    this.id= order.id??'';
    this.customer_note = order.customer_note;
    this.totalPrice = new Price(order.total_price);
    this.created_at = order.created_at??'';
    this.line_items = order.line_items??[];
    this.billing_address = order.billing_address;
    this.shipping_address = order.shipping_address;
    this.invoices = order['invoices'];
    this.lineItems = this.line_items.map((lineItem) => {
      lineItem.price = new Price(lineItem.price);
      return lineItem as LineItem;
    })
    
    

  }
}