import { Injectable } from '@angular/core';

import { CartQuery } from './cart.query';

import { CartStore, IAddress, IOrder } from './cart.store';
import { IPrice, IProduct } from '@app/data-interfaces';
import { ICart, ICartLine } from './cart.interface';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { RequestService } from '@pisci/requestManager';
import { cloneDeep } from 'lodash';
import { Utils } from '@app/utils';

@Injectable({ providedIn: 'root' })
export class CartService {
  beforeAdd$ = new Subject();
  afterAdd$ = new Subject();
  constructor(
    private cartStore: CartStore,
    private cartQuery: CartQuery,
    private rm: RequestService
  ) {
    this.cartQuery
      .selectAll()
      .pipe(
        switchMap((entitites) => {
          return of(this.calculateTotal(entitites));
        }),
        tap((total) => {
          this.updateTotal(total);
        })
      )
      .subscribe();
  }

  setTotalDiscount(discount: number | string) {
    this.cartStore.update({ totalDiscount: Utils.getNumber(discount) });
  }

  getOrder() {
    return this.cartQuery.getValue().orderDraft;
  }

  addLineItemToCart(
    amountNet: number,
    amountGross: number,
    name: string,
    quantity: number
  ) {
    let cart = this.cartQuery.getValue()['cart'];
    cart.lines.push({ amountNet, amountGross, name, quantity });
    this.cartStore.update({ cart });
  }

  addProductQuantity(product: IProduct, diffQuantity: number) {
    let newQuantity = diffQuantity;
    let lines = this.cartQuery.getAll();
    if (product.stackable) {
      for (let line of lines) {
        if (line.productId == product.id && line.id) {
          newQuantity = line.quantity + diffQuantity;
        }
      }
    }

    return this.updateProductQuantity(product, newQuantity);
  }

  updateProductQuantity(product: IProduct, quantity: number) {
    let lines = this.cartQuery.getAll();
    let highestId = lines.reduce((prev, line: any) => {
      return prev < line.id ? line.id : prev;
    }, 1);
    let localProduct = cloneDeep(product);
    if (!localProduct.price.amountNet || !localProduct.price.amountGross) {
      localProduct.price.amountNet = Number.parseFloat(
        localProduct.price.amount_net
      );
      localProduct.price.amountGross = Number.parseFloat(
        localProduct.price.amount_gross
      );
    }

    if (localProduct.stackable == undefined) {
      localProduct.stackable = true;
    }
    if (localProduct.stackable) {
      for (let line of lines) {
        if (line.productId == localProduct.id && line.id) {
          let newLine = {
            ...line,
            quantity: line.quantity + quantity,
          };
          this.cartStore.update(line.id, newLine);
          this.afterAdd$.next(line.id);
          return;
        }
      }
    }
    this.cartStore.add({
      id: highestId + 1,
      amountNet: localProduct.price.amountNet,
      amountGross: localProduct.price.amountGross,
      name: localProduct.name,
      productId: localProduct.id,
      price: localProduct.price,
      product: product,
      quantity,
    }); /*
    this.cartQuery
      .select()
      .pipe(take(1))
      .subscribe((state) => {
        if (state.orderDraft.id) {
          this.cartStore.update({ orderDraft: {} });
        }
      });*/
    this.afterAdd$.next(highestId + 1);
  }
  updateLine(line: ICartLine) {
    if (line.id) {
      this.cartStore.update(line.id, line);
    }
  }

  removeLine(line: ICartLine) {
    this.cartStore.remove(line.id);
  }
  clearCart() {
    this.cartStore.remove();
    this.cartStore.update({
      cartTotal: 0,
      totalPrice: undefined,
      orderDraft: {},
      shippingAddress: undefined,
      billingAddress: undefined,
      userId: undefined,
      customerId: undefined,
      data: undefined,
    });
    //  this.cartStore.reset();
  }

  createOrderForCart() {
    return combineLatest([
      this.selectCart(),
      this.cartQuery.select((state) => state.orderDraft),
    ]).pipe(
      take(1),
      mergeMap(([cart, orderDraft]) => {
        if (orderDraft['id']) {
          return of();
        }
        return this.rm.post('order', cart).pipe(
          tap((order: any) => {
            order as IOrder;
            console.log(order);
            this.cartStore.update({ orderDraft: order });
          })
        );
      })
    );
  }

  selectOrderDraft() {
    return this.cartQuery.select((state) => state.orderDraft);
  }

  getCart(): ICart {
    return { lines: this.cartQuery.getAll() };
  }

  getCount(): number {
    return this.cartQuery.getCount();
  }

  selectCart(): Observable<ICart> {
    return combineLatest([
      this.cartQuery.selectAll(),
      this.cartQuery.select(),
    ]).pipe(
      map(([lines, state]) => {
        let additionalFields: any = {};
        if (state.userId) {
          additionalFields.userId = state.userId;
        }
        if (state.data) {
          additionalFields.data = state.data;
        }
        return {
          ...additionalFields,
          lines,
          shippingAddress: state.shippingAddress,
          billingAddress: state.billingAddress,
        };
      })
    );
  }
  selectCartLines(): Observable<ICartLine[]> {
    return this.cartQuery.selectAll();
  }

  selectCount(): Observable<number> {
    return this.cartQuery.selectCount();
  }

  selectTotal(): Observable<number> {
    return this.cartQuery.select((state) => state.cartTotal);
  }

  calculateTotal(entities: ICartLine[]) {
    let total = 0;
    entities.forEach((entitty) => {
      total += entitty.amountGross * entitty.quantity;
    });

    return total;
  }

  updateTotal(total: number) {
    this.cartStore.update({ cartTotal: total });
  }
  setAddresses(
    billingAddress: IAddress,
    shippingAddress: IAddress | undefined = undefined
  ) {
    this.cartStore.update({ billingAddress, shippingAddress });
  }

  getBillingAddress() {
    return this.cartQuery.getValue().billingAddress;
  }

  setData(data: any) {
    this.cartStore.update({ data });
  }
  getPaymentUrlForOrder() {
    return this.cartQuery
      .select((state) => state.orderDraft)
      .pipe(
        take(1),
        mergeMap((order) => {
          return this.rm.get('/order/' + order['id'] + '/payment').pipe(
            map((res: any) => {
              return res.url;
            })
          );
        })
      );
  }

  setUserId(id: number) {
    this.cartStore.update({ userId: id });
  }
}
